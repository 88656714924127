@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
.ant-card {
  box-shadow: none !important;
}
.ant-input {
  height: 33px;
}
.ant-form-item-label > label {
  font-size: 13px !important;
}
.ant-form-item-explain-error {
  font-size: 12px !important;
  font-weight: 400 !important;
}
* {
  font-family: "Rubik", sans-serif !important;
  box-sizing: border-box;
}
p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
}
.bold {
  font-weight: 500;
  font-size: 14px;
}
.ant-table-content {
  overflow: auto hidden !important;
}
